/**
 * @typedef {import("@microsoft/applicationinsights-web").ApplicationInsights} ApplicationInsights
 *
 * Snippet configuration
 * @typedef {Object} SnippetConfig
 * @property {string=} src - The SDK URL Source
 * @property {string=} [name="appInsights"] - Global SDK Instance name defaults to "appInsights" when not supplied
 * @property {number=} [ld=0] - Defines the load delay (in ms) before attempting to load the sdk. -1 = block page load and add to head. (default) = 0ms load after timeout
 * @property {boolean=} [useXhr=false] - Use XHR instead of fetch to report failures (if available)
 * @property {"anonymous"=} crossOrigin - When supplied this will add the provided value as the cross origin attribute on the script tag
 * @property {((sdk: ApplicationInsights) => void)=} onInit - // Once the application insights instance has loaded and initialized this callback function will be called with 1 argument -- the sdk instance (DO NOT ADD anything to the sdk.queue -- As they won't get called)
 * @property {import("@microsoft/applicationinsights-core-js").IConfiguration & import("@microsoft/applicationinsights-common").IConfig} cfg - Application Insights Configuration
 */

/**
 * Loads Application Insights SDK from remote URL and initializes it.
 * This is the only way how to load SDK from specific URL.
 * @see https://github.com/microsoft/ApplicationInsights-JS#snippet-setup-ignore-if-using-npm-setup
 * @see https://github.com/microsoft/ApplicationInsights-JS/blob/master/AISKU/snippet/snippet.js
 * @param {Window} win
 * @param {Document} doc
 * @param {SnippetConfig} snipConfig
 */

import { ApplicationInsights } from "@microsoft/applicationinsights-web/browser/ai.2.js";

export default function (win, doc, snipConfig) {
    var strDisableExceptionTracking = "disableExceptionTracking";
    var sdkInstanceName = "appInsightsSDK"; // required for Initialization to find the current instance
    var aiName = snipConfig.name || "appInsights"; // provide non default instance name through snipConfig name value
    if (snipConfig.name || win[sdkInstanceName]) {
        // Only set if supplied or another name is defined to avoid polluting the global namespace
        win[sdkInstanceName] = aiName;
    }
    var aiSdk =
        win[aiName] ||
        (function (aiConfig) {
            var appInsights = {
                initialize: true, // initialize sdk on download
                queue: [],
                sv: "5", // Track the actual snippet version for reporting.
                version: 2.0, // initialization version, if this is not 2.0 the previous scripts fail to initialize
                config: aiConfig,
            };

            // capture initial cookie
            try {
                appInsights.cookie = doc.cookie;
            } catch (e) {}

            function _createMethods(methods) {
                while (methods.length) {
                    (function (name) {
                        // Define a temporary method that queues-up a the real method call
                        appInsights[name] = function () {
                            // Capture the original arguments passed to the method
                            var originalArguments = arguments;
                            // If we have detected that the main script failed to load then stop queuing events that will never be processed
                            // Queue-up a call to the real method
                            appInsights.queue.push(function () {
                                // Invoke the real method with the captured original arguments
                                appInsights[name].apply(appInsights, originalArguments);
                            });
                        };
                    })(methods.pop());
                }
            }

            var track = "track";
            var trackPage = "TrackPage";
            var trackEvent = "TrackEvent";
            _createMethods([
                track + "Event",
                track + "PageView",
                track + "Exception",
                track + "Trace",
                track + "DependencyData",
                track + "Metric",
                track + "PageViewPerformance",
                "start" + trackPage,
                "stop" + trackPage,
                "start" + trackEvent,
                "stop" + trackEvent,
                "addTelemetryInitializer",
                "setAuthenticatedUserContext",
                "clearAuthenticatedUserContext",
                "flush",
            ]);

            // expose SeverityLevel enum
            appInsights["SeverityLevel"] = {
                Verbose: 0,
                Information: 1,
                Warning: 2,
                Error: 3,
                Critical: 4,
            };

            // Collect global errors
            // Note: ApplicationInsightsAnalytics is the extension string identifier for
            //  AppAnalytics. It is defined in ApplicationInsights.ts:ApplicationInsights.identifer
            var analyticsCfg = (aiConfig.extensionConfig || {}).ApplicationInsightsAnalytics || {};
            if (
                !(
                    aiConfig[strDisableExceptionTracking] === true ||
                    analyticsCfg[strDisableExceptionTracking] === true
                )
            ) {
                var method = "onerror";
                _createMethods(["_" + method]);
                var originalOnError = win[method];
                win[method] = function (message, url, lineNumber, columnNumber, error) {
                    var handled =
                        originalOnError &&
                        originalOnError(message, url, lineNumber, columnNumber, error);
                    if (handled !== true) {
                        appInsights["_" + method]({
                            message: message,
                            url: url,
                            lineNumber: lineNumber,
                            columnNumber: columnNumber,
                            error: error,
                        });
                    }

                    return handled;
                };
                aiConfig.autoExceptionInstrumented = true;
            }

            return appInsights;
        })(snipConfig.cfg);

    // global instance must be set in this order to mitigate issues in ie8 and lower
    win[aiName] = aiSdk;

    // This whole initialization code was copied from "ai.2.js" file and slightly modified
    // The whole purpose of this is skip request for the remote SDK file because in our case
    // it is already located in the vendors bundle (contains all NPM libraries from node_modules).
    //
    // There is one more reason then just better performance by not having the request - and that is
    // an exception: "SDK LOAD Failure: Failed to load Application Insights SDK script", more info
    // here: https://github.com/microsoft/ApplicationInsights-JS/blob/master/docs/SdkLoadFailure.md
    //
    // So by importing the @microsoft/applicationinsights-web/browser/ai.2.js at the top of this file
    // it takes and evaluates the "ai.2.js" code from vendors bundle. Unfortunately, the code there
    // expects at the time of evaluation to have the snippet set to the window. Dynamic import from
    // vendors bundle is not possible, so the only option remaining is to do the initialization again here:
    var Undefined = "undefined";
    function _logWarn(message) {
        var _console = typeof console !== Undefined ? console : null;
        if (_console && _console.warn) {
            _console.warn('Failed to initialize AppInsights JS SDK for instance ' + (aiName || '<unknown>') + ' - ' + message);
        }
    }
    try {
        if (typeof window !== Undefined) {
            var _window = window;
            if (typeof JSON !== Undefined) {
                if (_window[aiName] !== undefined) {
                    if (aiSdk.version >= 2.0 && _window[aiName].initialize) {
                        var applicationInsights = new ApplicationInsights(aiSdk);
                        //_legacyCookieMgr(); // this is not needed, the cookie manager is present anyway and we don't support legacy browsers.
                        applicationInsights.updateSnippetDefinitions(aiSdk);
                        applicationInsights.loadAppInsights();
                    }
                }
            }
            else {
                _logWarn("Missing JSON - you must supply a JSON polyfill!");
            }
        }
        else {
            _logWarn("Missing window");
        }
    }
    catch (e) {
        _logWarn(e.message);
    }
    // End of copied and modified code

    if (!(snipConfig.cfg.oriDisablePageViewTracking === true)) {
        aiSdk.trackPageView({});
    }

    if (snipConfig.onInit) {
        snipConfig.onInit(aiSdk);
    }
}
