// @ts-check
import downloadAndSetupAI from "./ApplicationInsights.snippet";

/**
 * @typedef {{
 *  allowAppInsights: boolean,
 *  instrumentationKey: string,
 *  sdkUrl: string,
 *  samplingPercentage: number,
 *  endpointUrl: string,
 *  disableFetchTracking: boolean,
 * }} ApplicationInsightOriConfig
 * @typedef {{ oriAppInsightsSettings: ApplicationInsightOriConfig }} WindowWithAIConfig
 */

// @ts-ignore TS-2352
const options = window && /** @type {WindowWithAIConfig} */ (window).oriAppInsightsSettings;

if (options && options.allowAppInsights) {
    /** @type {import("@microsoft/applicationinsights-core-js").IConfiguration & import("@microsoft/applicationinsights-common").IConfig} */
    var config = {
        instrumentationKey: options.instrumentationKey,
        samplingPercentage: options.samplingPercentage,
        disableFetchTracking: options.disableFetchTracking,
        disableAjaxTracking: options.disableAjaxTracking,
        oriDisablePageViewTracking: options.oriDisablePageViewTracking,
        disableCorrelationHeaders: options.disableCorrelationHeaders
    };

    if (options.endpointUrl) {
        config.endpointUrl = options.endpointUrl;
    }

    downloadAndSetupAI(window, document, {
        crossOrigin: "anonymous",
        cfg: config,
        onInit: function (sdk) {
            // Ignore this annoying error caused by FB crawlers: https://github.com/aFarkas/lazysizes/issues/520
            sdk.addTelemetryInitializer(function (envelope) {
                var params = new URLSearchParams(location.search);
                var fbclidCheck = params.get("fbclid");
                var isIllegalInvocationEx =
                    envelope.data.message &&
                    envelope.data.message.indexOf("Illegal invocation") > -1;

                return !(fbclidCheck && isIllegalInvocationEx);
            });
        },
    });
}
